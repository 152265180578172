import NotificationManager from "../../Infrastructure/NotificationManager";
import { JQData } from "../JQuery/JQData";
import { JQHtml } from "../JQuery/JQHtml";
import { JQSelector } from "../JQuery/JQSelector";
import { JQValue } from "../JQuery/JQValue";
export class CalcHelper {
}
CalcHelper.ResetResult = () => {
    const votosValidosControl = JQSelector.GetControl("#VotosValidos");
    JQValue.SetValue(votosValidosControl, 0);
    const cocienteControl = JQSelector.GetControl("#Cociente");
    JQValue.SetValue(cocienteControl, 0);
    const subcocienteControl = JQSelector.GetControl("#Subcociente");
    JQValue.SetValue(subcocienteControl, 0);
    JQHtml.SetInnerHtmlById("#ResultContainer", "");
};
CalcHelper.SetCalResult = (parties, votosValidos, cociente, subcociente) => {
    let template = "";
    const count = parties.length;
    for (let i = 0; i < count; i++) {
        const party = parties[i];
        const escanosTotales = party.EscanosPorCociente + party.EscanosPorResiduo;
        template += `
            <tr>
		    	<td>Partido ${party.Ordinal}</td>
		    	<td>${party.Votos}</td>
		    	<td>${party.Residuo.toFixed(1)}</td>
                <td>${party.EscanosPorCociente}</td>
		    	<td>${party.EscanosPorResiduo}</td>
		    	<td>${escanosTotales}</td>
		    </tr>
            `;
    }
    const votosValidosControl = JQSelector.GetControl("#VotosValidos");
    JQValue.SetValue(votosValidosControl, CalcHelper.ToFixedIfNecessary(votosValidos, 1));
    const cocienteControl = JQSelector.GetControl("#Cociente");
    JQValue.SetValue(cocienteControl, CalcHelper.ToFixedIfNecessary(cociente, 1));
    const subcocienteControl = JQSelector.GetControl("#Subcociente");
    JQValue.SetValue(subcocienteControl, CalcHelper.ToFixedIfNecessary(subcociente, 1));
    JQHtml.SetInnerHtmlById("#ResultContainer", template);
};
CalcHelper.ToFixedIfNecessary = (value, dp) => {
    return +value.toFixed(dp);
};
CalcHelper.CalculatePoliticalParties = () => {
    const partyControls = CalcHelper.GetPartyControls();
    if (partyControls === null) {
        return [];
    }
    const result = [];
    const count = partyControls.length;
    for (let i = 0; i < count; i++) {
        const control = JQHtml.WrapElement(partyControls[i]);
        const ordinal = JQData.GetNumber(control, "Ordinal");
        const val = JQValue.GetNumber(control);
        const partido = {
            Ordinal: ordinal,
            Votos: val !== null ? val : 0,
            Residuo: 0,
            EscanosPorCociente: 0,
            EscanosPorResiduo: 0,
        };
        result.push(partido);
    }
    const sortedByOrdinal = CalcHelper.SortPartiesByOrdinal(result);
    return sortedByOrdinal;
};
CalcHelper.FilterBySubquotient = (parties, subquotient) => {
    const result = [];
    const count = parties.length;
    for (let i = 0; i < count; i++) {
        const party = parties[i];
        if (party.Votos > subquotient) {
            result.push(party);
        }
    }
    return result;
};
CalcHelper.SortPartiesByVotes = (parties) => {
    return parties.sort(CalcHelper.SortPartiesByVotesInternal);
};
CalcHelper.SortPartiesByRemainder = (parties) => {
    return parties.sort(CalcHelper.SortPartiesByRemainderInternal);
};
CalcHelper.SortPartiesByOrdinal = (parties) => {
    return parties.sort(CalcHelper.SortPartiesByOrdinalInternal);
};
CalcHelper.SortPartiesByRemainderInternal = (a, b) => {
    if (a.Residuo > b.Residuo) {
        return -1;
    }
    if (a.Residuo < b.Residuo) {
        return 1;
    }
    return 0;
};
CalcHelper.SortPartiesByVotesInternal = (a, b) => {
    if (a.Votos > b.Votos) {
        return -1;
    }
    if (a.Votos < b.Votos) {
        return 1;
    }
    return 0;
};
CalcHelper.SortPartiesByOrdinalInternal = (a, b) => {
    if (a.Ordinal < b.Ordinal) {
        return -1;
    }
    if (a.Ordinal > b.Ordinal) {
        return 1;
    }
    return 0;
};
CalcHelper.CalculateValidVotes = (parties) => {
    let result = 0;
    const count = parties.length;
    for (let i = 0; i < count; i++) {
        const party = parties[i];
        result += party.Votos;
    }
    return result;
};
CalcHelper.GetPartyCount = () => {
    const control = JQSelector.GetControl("#DropDownCantidadPartidos");
    const result = JQValue.GetNumber(control);
    if (result === null) {
        return 0;
    }
    return result;
};
CalcHelper.GetAvailableSpots = () => {
    const control = JQSelector.GetControl("#TxtEscanosDisponibles");
    const result = JQValue.GetNumber(control);
    if (result === null) {
        return 0;
    }
    return result;
};
CalcHelper.GetPartyControls = () => {
    let isValid = true;
    const cantidadPartidos = CalcHelper.GetPartyCount();
    if (cantidadPartidos === 0) {
        isValid = false;
        NotificationManager.Warning("Debes seleccionar una cantidad de partidos.");
    }
    else if (cantidadPartidos < 0) {
        isValid = false;
        NotificationManager.Warning("La cantidad de partidos debe ser mayor a cero.");
    }
    const cantidadEscanos = CalcHelper.GetAvailableSpots();
    if (cantidadEscanos === 0) {
        isValid = false;
        NotificationManager.Warning("La cantidad de Escaños Disponibles debe ser mayor a cero.");
    }
    else if (cantidadEscanos < 0) {
        isValid = false;
        NotificationManager.Warning("La cantidad de Escaños Disponibles debe ser mayor a cero.");
    }
    if (isValid === false) {
        return null;
    }
    const partyControls = JQSelector.GetControlByClass("VotosPartido").toArray();
    if (partyControls === undefined || partyControls === null) {
        isValid = false;
        NotificationManager.Error("Hubo un error al leer los votos de los partidos.");
    }
    else {
        const partyCount = partyControls.length;
        if (partyCount < 1) {
            isValid = false;
            NotificationManager.Error("Hubo un error al leer los votos de los partidos.");
        }
    }
    if (isValid === false) {
        return null;
    }
    return partyControls;
};
