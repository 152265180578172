import { CalcHelper } from "../../Helpers/Calc/CalcHelper";
import { JQHtml } from "../../Helpers/JQuery/JQHtml";
import { JQSelector } from "../../Helpers/JQuery/JQSelector";
import NotificationManager from "../../Infrastructure/NotificationManager";
export class CalcOperations {
}
CalcOperations.Calcular = () => {
    CalcHelper.ResetResult();
    const parties = CalcHelper.CalculatePoliticalParties();
    if (parties === null) {
        return;
    }
    const count = parties.length;
    if (count < 1) {
        return;
    }
    const validVotes = CalcHelper.CalculateValidVotes(parties);
    if (validVotes < 1) {
        NotificationManager.Warning("Los votos de los partidos deben sumar mas que cero.");
        return;
    }
    const escanos = CalcHelper.GetAvailableSpots();
    const cociente = validVotes / escanos;
    const subcociente = cociente / 2;
    const filteredParties = CalcHelper.FilterBySubquotient(parties, subcociente);
    const filteredCount = filteredParties.length;
    if (filteredCount < 1) {
        NotificationManager.Warning("Al menos un partido debe superar el subcociente.");
        return;
    }
    const sortedByVotes = CalcHelper.SortPartiesByVotes(filteredParties);
    let escanosRestantes = escanos;
    for (let i = 0; i < filteredCount; i++) {
        if (escanosRestantes < 1) {
            break;
        }
        const party = sortedByVotes[i];
        let votes = party.Votos;
        if (votes < cociente) {
            party.Residuo = votes;
            continue;
        }
        while (votes > cociente && escanosRestantes > 0) {
            party.EscanosPorCociente++;
            escanosRestantes--;
            votes -= cociente;
        }
        party.Residuo = votes;
    }
    const sortedByRemainder = CalcHelper.SortPartiesByRemainder(filteredParties);
    while (escanosRestantes > 0) {
        for (let i = 0; i < filteredCount; i++) {
            if (escanosRestantes < 1) {
                break;
            }
            const party = sortedByRemainder[i];
            party.EscanosPorResiduo++;
            escanosRestantes--;
        }
    }
    CalcHelper.SetCalResult(parties, validVotes, cociente, subcociente);
};
CalcOperations.Reset = () => {
    CalcHelper.ResetResult();
    const contenedorPartidos = JQSelector.GetControl("#ContenedorInputsPartidos");
    JQHtml.SetInnerHtml(contenedorPartidos, "");
};
CalcOperations.CantidadPartidosOnChange = () => {
    CalcHelper.ResetResult();
    const contenedorPartidos = JQSelector.GetControl("#ContenedorInputsPartidos");
    JQHtml.SetInnerHtml(contenedorPartidos, "");
    const cantidadPartidos = CalcHelper.GetPartyCount();
    if (cantidadPartidos === 0) {
        return;
    }
    let template = "";
    const mitad = Math.ceil(cantidadPartidos / 2);
    for (let i = 0; i < mitad; i++) {
        const ordinal = i + 1;
        const ordinal2 = ordinal + mitad;
        template += `
            <div class="col-2 col-12-xsmall">
				<h3>Partido ${ordinal}</h3>
			</div>
			<div class="col-4 col-12-xsmall">
				<input type="number" id="TxtVotosValidosPartido${ordinal}" class="VotosPartido" min="0" value="" data-ordinal="${ordinal}" placeholder="Votos Válidos" />
			</div>
            `;
        if (ordinal2 <= cantidadPartidos) {
            template += `
                <div class="col-2 col-12-xsmall">
			    	<h3>Partido ${ordinal2}</h3>
			    </div>
			    <div class="col-4 col-12-xsmall">
			    	<input type="number" id="TxtVotosValidosPartido${ordinal2}" class="VotosPartido" min="0" value="" data-ordinal="${ordinal2}" placeholder="Votos Válidos" />
			    </div>
                `;
        }
    }
    JQHtml.SetInnerHtml(contenedorPartidos, template);
};
