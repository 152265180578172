import toastr from "toastr";
var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["Info"] = 0] = "Info";
    NotificationType[NotificationType["Success"] = 1] = "Success";
    NotificationType[NotificationType["Warning"] = 2] = "Warning";
    NotificationType[NotificationType["Error"] = 3] = "Error";
    NotificationType[NotificationType["ErrorPersistent"] = 4] = "ErrorPersistent";
    NotificationType[NotificationType["Exception"] = 5] = "Exception";
})(NotificationType || (NotificationType = {}));
class NotificationManager {
}
NotificationManager._baseOptions = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: false,
    positionClass: "toast-bottom-right",
    preventDuplicates: true,
    onclick: undefined,
    showDuration: 300,
    hideDuration: 600,
    timeOut: 3000,
    extendedTimeOut: 800,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
};
NotificationManager.Info = (msg, durationInMilliseconds) => {
    NotificationManager.Notify(NotificationType.Info, msg, durationInMilliseconds);
};
NotificationManager.Success = (msg) => {
    NotificationManager.Notify(NotificationType.Success, msg, 750);
};
NotificationManager.Warning = (msg) => {
    NotificationManager.Notify(NotificationType.Warning, msg, null);
};
NotificationManager.Error = (msg) => {
    NotificationManager.Notify(NotificationType.Error, msg, null);
};
NotificationManager.ErrorPersistent = (msg) => {
    NotificationManager.Notify(NotificationType.Error, msg, 0);
};
NotificationManager.Exception = (msg, durationInMilliseconds) => {
    NotificationManager.Notify(NotificationType.Error, msg, durationInMilliseconds);
};
NotificationManager.Close = () => {
    toastr.remove();
};
NotificationManager.Notify = (type, msg, duration) => {
    if (typeof msg !== "string") {
        msg = msg.toString();
    }
    const options = Object.assign({}, NotificationManager._baseOptions);
    if (duration !== null) {
        options.timeOut = duration;
        if (duration === 0) {
            options.extendedTimeOut = 0;
        }
    }
    switch (type) {
        case NotificationType.Error:
        case NotificationType.ErrorPersistent:
            toastr.error(msg, undefined, options);
            break;
        case NotificationType.Exception:
            options.positionClass = "toast-top-right";
            toastr.error(msg, undefined, options);
            break;
        case NotificationType.Success:
            toastr.success(msg, undefined, options);
            break;
        case NotificationType.Info:
            options.positionClass = "toast-top-right";
            toastr.info(msg, undefined, options);
            break;
        case NotificationType.Warning:
            toastr.warning(msg, undefined, options);
            break;
        default:
            break;
    }
};
export default NotificationManager;
