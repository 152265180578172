export class NumberHelper {
}
NumberHelper.Parse = (val) => {
    if (val === undefined) {
        return null;
    }
    if (val === null) {
        return null;
    }
    if (typeof val === "number") {
        if (isNaN(val) === true) {
            return null;
        }
        return val;
    }
    if (typeof val !== "string") {
        return null;
    }
    const trimmedVal = val.trim();
    if (trimmedVal === "") {
        return null;
    }
    const parsed = parseFloat(trimmedVal);
    if (isNaN(parsed) === true) {
        return null;
    }
    return parsed;
};
