import { NumberHelper } from "../NumberHelper";
import { StringHelper } from "../StringHelper";
import { JQSelector } from "./JQSelector";
export class JQValue {
}
JQValue.GetString = (control) => {
    const isInput = control.is("input, textarea, select");
    if (isInput === true) {
        const inputVal = control.val();
        return StringHelper.ToString(inputVal);
    }
    const txtVal = control.text();
    return StringHelper.ToString(txtVal);
};
JQValue.GetNumber = (control) => {
    const inputVal = JQValue.GetString(control);
    return NumberHelper.Parse(inputVal);
};
JQValue.GetBoolean = (control) => {
    const isCheckBox = control.is(":checkbox");
    if (isCheckBox === true) {
        const checked = control.prop("checked") === true;
        return checked;
    }
    const val = JQValue.GetString(control);
    if (val === null) {
        return null;
    }
    const isBoolean = StringHelper.IsBoolean(val);
    if (isBoolean === false) {
        return null;
    }
    return val.toLowerCase() === "true";
};
JQValue.GetStringFromChild = (parent, childId) => {
    const control = JQSelector.GetChild(parent, childId);
    const value = JQValue.GetString(control);
    return value;
};
JQValue.GetNumberFromChild = (parent, childId) => {
    const control = JQSelector.GetChild(parent, childId);
    const value = JQValue.GetNumber(control);
    return value;
};
JQValue.GetBooleanFromChild = (parent, childId) => {
    const control = JQSelector.GetChild(parent, childId);
    const value = JQValue.GetBoolean(control);
    return value;
};
JQValue.SetValue = (control, val) => {
    const isInput = control.is("input, textarea, select");
    if (isInput === true) {
        control.val(val);
    }
    else {
        control.text(val);
    }
};
JQValue.SetValueForChild = (parent, childId, val) => {
    const control = JQSelector.GetChild(parent, childId);
    JQValue.SetValue(control, val);
    return control;
};
