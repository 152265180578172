import $ from "jquery";
export class JQHtml {
}
JQHtml.WrapElement = (element) => {
    return $(element);
};
JQHtml.WrapHtmlString = (htmlString) => {
    return $(htmlString);
};
JQHtml.SetInnerHtml = (control, htmlData) => {
    control.html(htmlData);
};
JQHtml.SetInnerHtmlById = (inputId, htmlData) => {
    const control = $(inputId);
    JQHtml.SetInnerHtml(control, htmlData);
};
JQHtml.LoadHtmlThen = (control, html, callback) => {
    $.when(control.html(html)).then(callback);
};
