class LogManager {
}
LogManager.GlobalErrorHandlerListener = (e) => {
    const hasValidErrorEvent = e !== undefined && e !== null;
    const hasValidError = hasValidErrorEvent === true && e.error !== undefined && e.error !== null;
    const hasValidErrorMessage = hasValidError === true && e.error.message !== undefined && e.error.message !== null;
    const hasValidStackTrace = hasValidError === true && e.error.stack !== undefined && e.error.stack !== null;
    const exception = {
        Message: hasValidErrorMessage === true ? e.error.message : "Unknown",
        StackTrace: hasValidStackTrace === true ? e.error.stack : "Not Available"
    };
    if (hasValidErrorMessage !== true && hasValidStackTrace !== true) {
        if (hasValidErrorEvent === true) {
            if (e.message !== undefined && e.message !== null) {
                if (typeof e.message === "string") {
                    if (e.message.indexOf("ResizeObserver") > -1) {
                        return;
                    }
                }
                exception.Message = e.message;
            }
            console.log(e);
            exception.StackTrace = JSON.stringify(e);
        }
    }
    LogManager.Error("Exception:", [exception]);
    return true;
};
LogManager.CloseEventCodes = {
    [1000]: "Normal Closure: The connection successfully completed whatever purpose for which it was created.",
    [1001]: "Going Away:  Either because of a server failure or because the browser is navigating away from the page that opened the connection.",
    [1002]: "Protocol Error:  Terminating the connection due to a protocol error.",
    [1003]: "Unsupported Data:  The connection is being terminated because the endpoint received data of a type it cannot accept (for example, a text-only endpoint received binary data).",
    [1004]: "Reserved. A meaning might be defined in the future.",
    [1005]: "No Status Received:  Indicates that no status code was provided even though one was expected.",
    [1006]: "Abnormal Closure:  Used to indicate that a connection was closed abnormally (that is, with no close frame being sent) when a status code is expected.",
    [1007]: "Invalid Frame Payload Data: Terminating the connection because a message was received that contained inconsistent data (e.g., non-UTF-8 data within a text message).",
    [1008]: "Policy Violation:  Terminating the connection because it received a message that violates its policy. This is a generic status code, used when codes 1003 and 1009 are not suitable.",
    [1009]: "Message Too Big:  Terminating the connection because a data frame was received that is too large.",
    [1010]: "Missing Extension: The client is terminating the connection because it expected the server to negotiate one or more extension, but the server didn't.",
    [1011]: "Internal Error:  The server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.",
    [1012]: "Service Restart:  The server is terminating the connection because it is restarting.",
    [1013]: "Try Again Later:  The server is terminating the connection due to a temporary condition, e.g. it is overloaded and is casting off some of its clients.",
    [1014]: "Bad Gateway:  The server was acting as a gateway or proxy and received an invalid response from the upstream server. This is similar to 502 HTTP Status Code.",
    [1015]: "TLS Handshake:  Reserved. Indicates that the connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).",
};
LogManager.ObjectAsException = (message, objectName, obj) => {
    const exception = {};
    exception.ExceptionType = objectName;
    if (obj !== undefined && obj !== null) {
        if (objectName === "CloseEvent" && obj.code !== undefined && obj.code !== "") {
            exception.Message = `${message} - ${obj.code}`;
            if (LogManager.CloseEventCodes[obj.code]) {
                exception.Code = `${obj.code} - ${LogManager.CloseEventCodes[obj.code]}`;
            }
        }
        else {
            exception.Message = message;
        }
        const propLogs = [];
        LogManager.ComposeObjectLogs(obj, objectName, propLogs, "");
        exception.StackTrace = propLogs.join("\n");
    }
    else {
        exception.StackTrace = "Not Available";
    }
    LogManager.Error("Exception:", [exception]);
};
LogManager._indent = "  ";
LogManager.ComposeObjectLogs = (obj, objectName, logs, currentIndent) => {
    const newIdent = currentIndent + LogManager._indent;
    logs.push(`${currentIndent}${objectName}:`);
    if (typeof obj === "string") {
        logs.push(`Error: ${obj}`);
        return;
    }
    let foundContent = false;
    for (const propKey in obj) {
        foundContent = true;
        const prop = obj[propKey];
        if (typeof prop !== "function") {
            if (typeof prop !== "object") {
                logs.push(`${newIdent}${propKey}: ${prop}`);
            }
            else {
                LogManager.ComposeObjectLogs(prop, propKey, logs, newIdent);
            }
        }
    }
    if (foundContent === false) {
        const props = Object.getOwnPropertyNames(obj);
        if (props !== undefined && props !== null && props.length > 0) {
            const propCount = props.length;
            for (let i = 0; i < propCount; i++) {
                const propKey = props[i];
                const prop = obj[propKey];
                if (typeof prop !== "function") {
                    if (typeof prop !== "object") {
                        logs.push(`${newIdent}${propKey}: ${prop}`);
                    }
                    else {
                        LogManager.ComposeObjectLogs(prop, propKey, logs, newIdent);
                    }
                }
            }
        }
    }
};
LogManager.NetworkInfo = (title, content, send, tabularData = undefined) => {
    const color = send ? "#6bbd24" : "#9e6cd4";
    const arrow = send ? "--> " : "<-- ";
    LogManager.LogColor("%c" + arrow + title, content, tabularData, color, console.info);
};
LogManager.Info = (title, content, tabularData = undefined) => {
    const color = "#4089ed";
    const prefix = "+++ ";
    LogManager.LogColor("%c" + prefix + title, content, tabularData, color, console.info);
};
LogManager.Error = (title, content, tabularData = undefined) => {
    const color = "#b82323";
    const prefix = "!!! ";
    LogManager.LogColor("%c" + prefix + title, content, tabularData, color, console.info);
};
LogManager.Warning = (title, content, tabularData = undefined) => {
    const color = "#e68312";
    const prefix = "*** ";
    LogManager.LogColor("%c" + prefix + title, content, tabularData, color, console.info);
};
LogManager.LogColor = (title, content, tabularData, color, logger) => {
    console.groupCollapsed(title, `color:${color};font-weight:900;`);
    if (content !== undefined) {
        for (let i = 0; i < content.length; i++) {
            const element = content[i];
            logger(element);
        }
    }
    if (tabularData !== undefined) {
        console.table(tabularData);
    }
    console.groupEnd();
};
export default LogManager;
