import $ from "jquery";
export class JQSelector {
}
JQSelector.GetControl = (inputId) => {
    return $(inputId);
};
JQSelector.GetControlByClass = (css) => {
    return $("." + css);
};
JQSelector.GetChild = (parent, childId) => {
    return parent.find(childId);
};
JQSelector.GetChildren = (parent) => {
    return parent.children();
};
JQSelector.GetParent = (control) => {
    return control.parent();
};
JQSelector.GetClosest = (control, identifier) => {
    return control.closest(identifier);
};
JQSelector.AppendChild = (parent, child) => {
    parent.append(child);
};
JQSelector.PrependChild = (parent, child) => {
    parent.prepend(child);
};
JQSelector.RemoveControl = (control) => {
    control.remove();
};
JQSelector.ReplaceWith = (currentElement, newElement) => {
    currentElement.replaceWith(newElement);
};
JQSelector.ForEach = (array, callback) => {
    return $.each(array, callback);
};
