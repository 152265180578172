import { NumberHelper } from "./NumberHelper";
export class StringHelper {
}
StringHelper.IsNullOrWhiteSpace = (val) => {
    if (val === undefined) {
        return true;
    }
    if (val === null) {
        return true;
    }
    if (val === "undefined") {
        return true;
    }
    if (val.toString().trim() === "") {
        return true;
    }
    return false;
};
StringHelper.IsNumber = (val) => {
    const parsed = NumberHelper.Parse(val);
    if (parsed === null) {
        return false;
    }
    return true;
};
StringHelper.IsBoolean = (val) => {
    if (val === "true") {
        return true;
    }
    if (val === "false") {
        return true;
    }
    return false;
};
StringHelper._charCodeZero = "0".charCodeAt(0);
StringHelper._charCodeNine = "9".charCodeAt(0);
StringHelper.IsDigit = (c) => {
    if (c.length > 1) {
        return false;
    }
    const char = c.charCodeAt(0);
    return char >= StringHelper._charCodeZero && char <= StringHelper._charCodeNine;
};
StringHelper.ToString = (val) => {
    if (val === undefined) {
        return null;
    }
    if (val === null) {
        return null;
    }
    if (typeof val === "number") {
        if (isNaN(val) === true) {
            return "NaN";
        }
        return val.toString();
    }
    if (typeof val !== "string") {
        return null;
    }
    const trimmedVal = val.trim();
    return trimmedVal;
};
