import { NumberHelper } from "../NumberHelper";
import { StringHelper } from "../StringHelper";
export class JQData {
}
JQData.GetString = (control, dataField) => {
    const val = control.data(dataField.toLowerCase());
    if (val === undefined || val === null) {
        return null;
    }
    return val.toString();
};
JQData.GetNumber = (control, dataField) => {
    const val = JQData.GetString(control, dataField);
    const numberVal = NumberHelper.Parse(val);
    if (numberVal === null) {
        return null;
    }
    return numberVal;
};
JQData.GetBoolean = (control, dataField) => {
    const val = JQData.GetString(control, dataField);
    if (val === null) {
        return null;
    }
    const isBoolean = StringHelper.IsBoolean(val);
    if (isBoolean === false) {
        return null;
    }
    return val.toLowerCase() === "true";
};
JQData.SetString = (control, dataField, dataValue) => {
    control.data(dataField.toLowerCase(), dataValue);
};
JQData.SetNumber = (control, dataField, dataValue) => {
    control.data(dataField.toLowerCase(), dataValue);
};
JQData.SetBoolean = (control, dataField, dataValue) => {
    control.data(dataField.toLowerCase(), dataValue);
};
